import {createSlice} from "@reduxjs/toolkit";
import {atlasSearchRecipes} from "./atlasThunk.js";
import { handleSearchFilters } from "./atlasUtils.js";

const initialSearchState = {
    searchTerm: "",
    dontIncludeIngredients: [],
    includeIngredients: [],
    dietType: "omnivore",
    filterKey: {id: 1, name: "Best Match", value: "score"},
    direction: {id: 1, name: "Descending", value: "desc"},
    pageNum: 1,
    status: "init",
    recipes: [],
    pagination: {
        currentPage: 1,
        itemsPerPage: 18,
        totalItems: -1,
        totalPages: -1,
    },
};

const searchSlice = createSlice({
    name: "search",
    initialState: initialSearchState,
    reducers: {
        setCurrentSearchValue: (state, action) => {
            state.searchTerm = action.payload;
        },
        updateSearchFilters: (state, action) => {
            state.filterKey = action.payload.key;
            state.direction = action.payload.direction;
        
            const unsortedRecipes = JSON.parse(JSON.stringify([...state.recipes]));
            const sortedRecipes = handleSearchFilters(unsortedRecipes, action.payload.key.value, action.payload.direction.value);

            // Update the recipes state to sortedRecipes
            state.recipes = sortedRecipes;
        },
        updateSearchSettings: (state, action) => {
            const {
                searchTerm,
                includeIngredients,
                dontIncludeIngredients,
                dietType,
                sortingFilters,
                page
            } = action.payload;

            if (page) state.pageNum = page

            if (dietType) state.dietType = dietType
            if (sortingFilters && Object.keys(sortingFilters).length) {
                state.filterKey = sortingFilters.key
                state.direction = sortingFilters.direction
            }
            if (searchTerm !== undefined) {
                state.searchTerm = searchTerm;
            }
            if (includeIngredients !== undefined) {
                state.includeIngredients = includeIngredients;
            }
            if (dontIncludeIngredients !== undefined) {
                state.dontIncludeIngredients = dontIncludeIngredients;
            }
        },
        clearRecipes: (state) => {
            state.recipes = []
        }
    },
    extraReducers: {
        [atlasSearchRecipes.pending]: (state) => {
            state.status = "loading";
        },
        [atlasSearchRecipes.fulfilled]: (state, { payload }) => {
            state.status = "success";
            state.recipes = payload.recipes;
            state.pagination = payload.pagination;
            state.pageNum = payload.pagination.currentPage;
        },
        [atlasSearchRecipes.rejected]: (state) => {
            state.status = "failed";
        },
      },
});

export const {setCurrentSearchValue, updateSearchSettings, clearRecipes, updateSearchFilters} =
    searchSlice.actions;

export const selectSortingFilters = (state) => ({
    key: state.atlas.filterKey,
    direction: state.atlas.direction
})

export default searchSlice.reducer;
