import { createAsyncThunk } from '@reduxjs/toolkit';
import { getRequest } from '../wwlAPI';
import { handleSearchFilters } from './atlasUtils';

export const atlasSearchRecipes = createAsyncThunk(
  'atlas/atlasSearchRecipes',
  async (payload, { getState }) => {
    try {
      const { atlas } = getState();
      let {
        searchTerm,
        dontIncludeIngredients,
        includeIngredients,
        dietType,
        filterKey,
        direction,
        pageNum,
        recipes
      } = atlas;

      const queryParams = new URLSearchParams({
        searchTerm,
        dietType,
        filterKey: filterKey.value,
        direction: direction.value,
        pageNum:  payload?.restart ? 1 : pageNum
      });

      includeIngredients.forEach(ingredient => queryParams.append('includeIngredients[]', ingredient));
      dontIncludeIngredients.forEach(ingredient => queryParams.append('dontIncludeIngredients[]', ingredient));

      const url = `/recipes/atlasRecipeSearch?${queryParams.toString()}`;
      const response = await getRequest(url);

      // Combine and sort recipes
      const combinedRecipes = [...recipes, ...(response.data.recipes || [])];
      const sortedRecipes = handleSearchFilters(combinedRecipes, filterKey.value, direction.value);

      // Assuming the API response includes pagination details, modify the return to include sorted recipes
      return {
        recipes: sortedRecipes,
        pagination: response.data.pagination
      };

    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);
