import React from "react";
import RecipeIngredients from "./components/RecipeIngredients";

const PrintDetails = React.forwardRef(
  (
    {
      prepTime,
      servingsCount,
      servings,
      cookTime,
      glutenFree,
      dairyFree,
      instructions,
      ingredients,
      title,
    },
    ref
  ) => {
    return (
      <>
      <style>
        {`
          @media print {
            body, html {
              width: 100%;
              overflow: visible;
            }
            .print-visible {
              overflow: visible !important;
              display: block !important;
            }
            .RecipeIngredients, .RecipeIngredients * {
              overflow: visible !important;
            }
          }
        `}
      </style>
      <div ref={ref} className="bg-white hidden print-visible">
        <h1 className="font-chivo font-bold text-2xl lg:text-2xl mt-4 lg:mt-0 text-center">
          {title}
        </h1>
        <div
          className={`font-chivo flex justify-around px-4 py-6 mb-4 gap-6 w-3/4 mx-auto`}
        >
          <div className="text-center">
            <h6 className="text-xs mb-2">Serving Size</h6>
            <h4 className="text-base whitespace-nowrap sm:whitespace-normal">
              {servingsCount}
              <br />
            </h4>
          </div>
          <div className="text-center">
            <h6 className="text-xs mb-2">Prep Time</h6>
            <h4 className="text-base whitespace-nowrap sm:whitespace-normal">
              {prepTime}
              <br /> mins
            </h4>
          </div>
          <div className="text-center">
            <h6 className="text-xs mb-2">Cook Time</h6>
            <h4 className="text-base">
              {cookTime}
              <br /> mins
            </h4>
          </div>
        </div>
        <div className="flex">
          <div className="w-[30%] px-3">
            <RecipeIngredients
              servings={servings}
              servingsCount={servingsCount}
              printable={true}
              dataToRender={ingredients}
              title={"Ingredients"}
              dairyFree={dairyFree}
              glutenFree={glutenFree}
            />
          </div>
          <div className="pr-3 w-[70%]">
            <RecipeIngredients
              servingsCount={servingsCount}
              servings={servings}
              printable={true}
              dataToRender={instructions}
              title={"Instructions"}
              dairyFree={dairyFree}
              glutenFree={glutenFree}
            />
          </div>
        </div>
      </div>
      </>
    );
  }
);

export default PrintDetails;
