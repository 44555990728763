import {Fragment, useRef, useState, useEffect} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {ExclamationIcon} from '@heroicons/react/outline'
import {useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux'
import { postanalytics } from '../../redux/analytics/analyticsThunk';

// This modal is for upgrading from BASIC to PRO

// https://workweeklunch.memberful.com/account/subscriptions/3295560/plan_changes/new

export default function BasicToProModal() {
  const deleteButtonRef = useRef(null)
  const location = useLocation().pathname;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const memberfulData = useSelector(state => state.user?.currentUser?.memberful)
  const currentUser = useSelector(state => state.user?.currentUser)

  const [open, setOpen] = useState(false)

  // TODO: If user has plan 105757, do not show this
  // TODO: If a user has a "basic" plan, do not show this
  // TODO: Track how many times this popup shows, and how many times the item gets clicked.

  // This is the date when we no longer will show this modal again
  const CUT_OFF_DATE = new Date('2024-04-30');

  useEffect(() => {
    console.log('ModalComponent useEffect triggered');
    console.log(memberfulData);

    const basicPlanIds = ["79422","86977","86978"]
    if (memberfulData?.plan_id && basicPlanIds.indexOf(memberfulData?.plan_id) !== -1) {
        console.log('Basic User, Potentially show modal.')
        const body = {
            popupId: "basicToPro",
            userEmail: currentUser.email,
            action: "seeModal"
        }
        dispatch(postanalytics(body))

    } else {
        console.log('Non-Basic user. Never show modal.')
        setOpen(false);
        return
    }

    const now = new Date();

    const today = new Date();
    console.log('Today\'s date:', today);

    if (today > CUT_OFF_DATE) {
      console.log('Beyond the cutoff date. Modal will not be shown.');
      setOpen(false);
    } else {
      const lastShown = localStorage.getItem("modal_last_shown_date");
      if (lastShown) {
        const lastShownDate = new Date(lastShown);
        console.log('Modal last shown date:', lastShownDate);

        // This is actually one week later:
        const oneWeekLater = new Date(lastShownDate.getTime() + 7 * 24 * 60 * 60 * 1000);

        // For testing, we can use a shorter time:
        // const oneWeekLater = new Date(lastShownDate.getTime() + 1000);
        console.log('Date one week after last shown:', oneWeekLater);

        if (today >= oneWeekLater) {
          console.log('A week has passed since last shown. Modal will be shown.');
          localStorage.setItem("modal_last_shown_date", now.toISOString());
          setOpen(true);
        } else {
          console.log('Not yet a week since last shown. Modal will not be shown.');
          setOpen(false);
        }
      } else {
        console.log('Modal has never been shown. It will be shown now.');
        localStorage.setItem("modal_last_shown_date", now.toISOString());
        setOpen(true);
      }
    }
  }, []);

  const handleAction = () => {
    const body = {
        popupId: "basicToPro",
        userEmail: currentUser.email,
        action: "clickUpgrade"
    }
    dispatch(postanalytics(body))
    setOpen(false)
    window.location.href = 'https://workweeklunch.memberful.com/account/subscriptions';
  }

  function handleCancel() {
    setOpen(false);
    if (location === '/meal-planning/meal-planner' || location === '/shopping-list' || location === '/meal-planning'){
      navigate('/');
    }
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-50 inset-0 overflow-y-auto" initialFocus={deleteButtonRef} onClose={e=>handleCancel()}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
          </Transition.Child>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div
                    className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-yellow-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationIcon className="h-6 w-6 text-yellow-600" aria-hidden="true"/>
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                      Did you know?
                    </Dialog.Title>
                    <div className="mt-2 space-y-3">
                      <p className="text-md text-gray-500">
                        It looks like you're on one of our basic plans... If you upgrade to Pro today, you'll get access to:
                      </p>
                      <ul className="text-md text-gray-500 pb-4">
                        <li>- Exclusive Meal Plans</li>
                        <li>- Custom Meal Planner</li>
                        <li>- Shopping List Generation</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="flex items-center justify-center py-2 px-4 font-chivo text-sm whitespace-nowrap lg:mb-0 px-4 rounded-lg bg-wwlOrange border border-transparent hover:bg-wwlWhite hover:text-wwlOrange hover:border-wwlOrange text-wwlWhite py-2 px-6 transition-colors duration-300 capitalize sm:ml-4 w-full sm:w-fit"
                  ref={deleteButtonRef}
                  onClick={() => {
                    handleAction()
                  }
                  }
                >
                  Go Pro Now!
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-0 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={() => handleCancel()}
                >
                  Continue to WWL
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}