import React, {useEffect, useState} from 'react';
import {StarIcon} from "@heroicons/react/outline";
import {StarIcon as StarIconFilled} from "@heroicons/react/solid";
import {getRequest, postRequest} from "../../../../redux/wwlAPI";
import {useSelector} from "react-redux";
import {selectCurrentUser} from "../../../../redux/user/userSlice";
import {toast} from "react-hot-toast";
import RatingAndCommentModal from '../../../../components/Modals/RatingAndCommentModal';
import Spinner from '../../../../components/DataTable/components/Spinner';

const Rating = ({id, handleNullUser, isPopup, notifyParent}) => {
  const [stars, setStars] = useState([]);
  const [rating, setRating] = useState(0);
  const [hovered, setHovered] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const selectedIcon = <StarIconFilled className='w-6 text-wwlYellow'/>;
  const deselectedIcon = <StarIcon className='w-6 text-wwlYellow'/>;
  const currentUser = useSelector(selectCurrentUser);
  const currentRecipe = useSelector(state => state.recipe.currentRecipe);

  const recipeStatus = useSelector(state => state.recipe.status)
  useEffect(() => {
    const newStars = [];
    for (let i = 0; i < 5; i++) {
      newStars.push(i + 1);
    }
    setStars(newStars);
  }, []);

  useEffect(() => {
    if (currentRecipe?.currentUserRating) {
      setRating(currentRecipe?.currentUserRating);
    }
    if (id && !isFetching && currentUser){
      setIsFetching(true);
      getRequest(`/ratings/get_rating/${id}`, {}).then(res => {
        setRating(res.data.score || 0);
        setIsFetching(false)
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, currentRecipe])

  const [showModal, setShowModal] = useState(false)

  const handleRatingClick = (newRating) => {
    if (!isPopup) {
      setShowModal(!showModal)
    } else {
      setRating(newRating);
      notifyParent(newRating);
    }
    
  }

  const hoverRating = (rating) => {
    setHovered(rating);
  }

  if (recipeStatus == 'pending') {
    return (
      <div>
        <Spinner />
      </div>
    )
  }

  return (
    <div className='mr-10 lg:mr-0'>
      <p className='font-chivo text-xs whitespace-nowrap mb-2'>Rate this recipe</p>
      <div className="flex">
        {stars.map(star => {
          return (
            <span
              key={`star-${star}`}
              style={{cursor: 'pointer'}}
              onClick={() => handleRatingClick(star)}
              onMouseEnter={() => {
                hoverRating(star);
              }}
              onMouseLeave={() => {
                hoverRating(0);
              }}
            >
              {rating < star ?
                hovered < star ? deselectedIcon : selectedIcon
                : selectedIcon
              }
            </span>
          );
        })}
      </div>
      <RatingAndCommentModal open={showModal} setOpen={setShowModal} rating={rating}/>
    </div>
  )
}

export default Rating;