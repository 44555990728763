import React from "react";
import {SearchIcon} from "@heroicons/react/solid";
import {XIcon} from "@heroicons/react/outline";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {updateSearchSettings} from "../../../redux/atlas/atlasSlice";

const SearchBarNew = ({closeModal}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const initSearchTerm = useSelector(state => state.atlas.searchTerm)

    function handleSearch(e) {
        if (e.key === 'Enter' && e.target.value !== '') {
            navigate('/explore-recipes?page=1&search=' + e.target.value)
            closeModal && closeModal('close')
        }
    }

    return (
        <>
            <label htmlFor="search" className="sr-only">
                Search
            </label>
            <div className="relative">
                <SearchIcon
                    className="absolute top-1/2 left-3 -translate-y-1/2 h-5 w-5 text-wwlBlack block md:text-wwlWhite"
                    aria-hidden="true"
                />
                <input type="text" placeholder={'Search for recipes'} value={initSearchTerm}
                       className={`bg-wwlWhite md:bg-wwlWhiteTransparent font-chivo h-10 w-full md:w-3/4 rounded-full border-none py-1.5 pl-10 text-sm font-normal md:placeholder:text-white md:text-white focus:ring-white`}
                       onChange={(e) => {dispatch(updateSearchSettings({searchTerm: e.target.value}))}}
                       onKeyDown={(e) => handleSearch(e)}/>
                <XIcon
                    className="block md:hidden absolute top-1/2 right-3 -translate-y-1/2 h-5 w-5 text-wwlBlack cross"
                    aria-hidden="true"
                    onClick={(e) => {
                        closeModal(e)
                    }}
                />
            </div>
        </>
    );
};

export default SearchBarNew;


