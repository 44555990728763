import React, {useEffect, useState} from "react";
import {XIcon} from "@heroicons/react/outline";
import ChipInput from "./ChipInput";
import SearchSortOptions from "./SearchSortOptions";
import {useDispatch, useSelector} from 'react-redux'
import {atlasSearchRecipes} from "../../../redux/atlas/atlasThunk";
import {clearRecipes, selectSortingFilters, updateSearchSettings} from "../../../redux/atlas/atlasSlice";
import {selectDairyFree, selectPrimaryDiet} from "../../../redux/user/userSlice";
import {useSearchParams} from "react-router-dom";

export default function SearchSettings({closePanel}) {
    const dispatch = useDispatch();
    const initSearchTerm = useSelector(state => state.atlas.searchTerm)
    const initIncludeIngredients = useSelector(state => state.atlas.includeIngredients)
    const initDontIncludeIngredients = useSelector(state => state.atlas.dontIncludeIngredients)
    const initSortingFilters = useSelector(selectSortingFilters)
    const primaryDiet = useSelector(selectPrimaryDiet)
    const isDairyFree = useSelector(selectDairyFree)

    const [includeIngredients, setIncludeIngredients] = useState(initIncludeIngredients);
    const [showFilters, setShowFilters] = useState(true);
    const [dontIncludeIngredients, setDontIncludeIngredients] = useState(initDontIncludeIngredients);
    const [sortingFilters, setSortingFilters] = useState(initSortingFilters);

    const [searchParams] = useSearchParams()
    const search = searchParams.get('search')


    const handleSearch = async (includedChips = null, dontIncludeChips = null) => {
        // Update global state just before searching
        dispatch(updateSearchSettings({
            dietType: isDairyFree && primaryDiet.toLowerCase() === "omnivore" ? "dairyFree" : primaryDiet.toLowerCase(),
            searchTerm: initSearchTerm,
            includeIngredients: includedChips || includeIngredients,
            dontIncludeIngredients: dontIncludeChips || dontIncludeIngredients,
            sortingFilters
        }));

        dispatch(clearRecipes())

        // Then, dispatch the action to perform the search
        dispatch(atlasSearchRecipes({
            restart: true
        }));
        closePanel();
    }

    // On initial load
    useEffect(() => {
        if (!initSearchTerm && !search) {
            handleSearch()
        }
    }, []);

    useEffect(() => {
        if (search) {
            handleSearch()
        }
    }, [search])


    return (
        <div className="px-6 bg-white w-96 z-50">
            <div className="flex justify-between">
                <div className="pt-4 pb-2 font-semibold opacity-100">Search</div>
                <button
                    className="pt-4 pb-2 font-semibold lg:hidden"
                    onClick={closePanel}
                >
                    <XIcon className="h-5 w-5"/>
                </button>
            </div>
            <div className="flex flex-col space-y-2 lg:flex">
                <input
                    placeholder="Enter Search Term"
                    className="grow focus:border-wwlOrange focus:ring-wwlOrange flex-1 outline-none w-full focus-none rounded-md"
                    value={initSearchTerm}
                    onChange={(e) => {
                        dispatch(updateSearchSettings({searchTerm: e.target.value}))
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') handleSearch();
                    }}
                    type="text"
                />

                <button onClick={() => {
                    handleSearch()
                }}
                        className="bg-wwlOrange hover:bg-white hover:text-wwlOrange border border-wwlOrange text-white text-center p-3 font-chivo rounded-md">
                    {initSearchTerm === ""
                        ? "Search All Recipes"
                        : `Search for "${initSearchTerm}"`}
                </button>
            </div>
            <div className={showFilters ? "flex flex-col overflow-y-auto" : "hidden"}>
                <div className="pt-4 pb-2 font-semibold">Sort by:</div>
                <SearchSortOptions filters={sortingFilters} setFilters={setSortingFilters}/>
                <div className="pt-4 pb-2 font-semibold">
                    Only include Recipes With ALL of these Ingredients:
                </div>
                <ChipInput initChips={includeIngredients} parentCb={(chips) => {
                    setIncludeIngredients(chips)
                    handleSearch(chips)
                }}/>

                <div className="pt-4 pb-2 font-semibold">
                    Don't include recipes with these Ingredients:
                </div>
                <ChipInput initChips={dontIncludeIngredients} parentCb={(chips) => {
                    setDontIncludeIngredients(chips)
                    handleSearch(null, chips)
                }}/>
            </div>
        </div>
    );
}
