import React from 'react';
import ContainerImage from "../assets/circular-container.png";
import {useNavigate} from "react-router-dom";
import {truncateText} from "../../../utilis/generalUtilis";
import {updateSearchSettings} from "../../../redux/atlas/atlasSlice";
import {useDispatch} from "react-redux";

const GlobalCuisineCard = ({globalCuisineTitle}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    return (
        <div onClick={() => {
            dispatch(updateSearchSettings({
                searchTerm: globalCuisineTitle
            }));
            navigate(`/explore-recipes?page=1&search=${globalCuisineTitle}`)
        }} className='h-fit rounded-full relative inline-block group cursor-pointer'>
            <img src={ContainerImage} alt="container"/>
            <h1 className='group-hover:text-wwlOrange group-hover:-rotate-12  transition-all duration-300 absolute top-2/4 left-2/4 text-base -translate-x-2/4 -translate-y-2/4 font-chivo font-black text-wwlDarkBlue'>{globalCuisineTitle === 'Mediterranean' ? truncateText(globalCuisineTitle, 9) : globalCuisineTitle}</h1>
        </div>
    );
};

export default GlobalCuisineCard;