import React, {useCallback} from 'react';
import SearchBarNew from "./SearchBarNew";

const SearchBarModal = ({setSearchbarOpen}) => {
    const closeModal = useCallback((event) => {
        if (typeof event === 'string' && event === 'close') return setSearchbarOpen(false);

        const closestOverlay = event.target.closest('svg');
        if (closestOverlay?.classList.contains('cross')) setSearchbarOpen(false);
        if (event.target.classList.contains('overlay-close')) setSearchbarOpen(false);
    }, [setSearchbarOpen]);

    return (
        <div className="fixed top-20 z-50 w-full inset-0 h-screen bg-gray-600 bg-opacity-75 overlay-close md:hidden"
             onClick={closeModal}>
            <div className={'absolute w-full px-8 mt-6'}>
                <SearchBarNew closeModal={closeModal}/>
            </div>
        </div>
    );
};

export default SearchBarModal;