import React from 'react';
import PropTypes from "prop-types";

const VideoPlayer = ({ videoUrl }) => {
  if (!videoUrl) {
    return <></>
  }
  // Extract video ID from the provided URL
  const isShorts = videoUrl.includes('/shorts/');
  const videoId = isShorts ? videoUrl.split('/shorts/')[1] : videoUrl.split('v=')[1];
  const embedUrl = `https://www.youtube.com/embed/${videoId}`;

  return (
    <div className="rounded-lg overflow-hidden w-full lg:mt-10 mb-10 lg:mb-0">
      <iframe
        width="100%"
        height="315"
        src={embedUrl}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default VideoPlayer;
