import React from "react";

export default function NoResults() {
  return (
    <div className="font-chivo w-full py-8 text-center">
      <h1 className="text-xl font-bold ">
        Oops! your search is too narrow!
      </h1>
      <p className="text-sm p-1">Try adjusting your filters, or searching for something different</p>
    </div>
  );
}
