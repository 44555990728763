import { createAsyncThunk } from '@reduxjs/toolkit';
import { postRequest } from '../wwlAPI';

export const postanalytics = createAsyncThunk('analytics/postanalytics', async (body) => {
    try {
        const res = await postRequest('/analytics/', body);
        return res.data;
    } catch (e) {
        console.error(e);
        throw e;
    }
});
