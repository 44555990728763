export const handleSearchFilters = (unsortedArray, filterKey, direction) => {
    return unsortedArray.sort((a, b) => {
        let x = a[filterKey];
        let y = b[filterKey];

        // Handle date conversion if the filterKey is 'publish_date'
        if (filterKey === 'publish_date') {
            x = new Date(x);
            y = new Date(y);
        }

        // Compare items
        if (x > y) {
            return direction === 'desc' ? -1 : 1;
        } else if (x < y) {
            return direction === 'desc' ? 1 : -1;
        } else {
            return 0;
        }
    });
};
