import React from 'react';
import ContainerImage from '../assets/container.svg'
import {updateSearchSettings} from "../../../redux/atlas/atlasSlice";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";

const CategoryCard = ({categoryTitle}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    return (
        <div onClick={() => {
            dispatch(updateSearchSettings({
                searchTerm: categoryTitle
            }));
            navigate(`/explore-recipes?page=1&search=${categoryTitle}`)
        }} className='rounded-b-full relative inline-block group cursor-pointer text-center'>
            <img src={ContainerImage} alt="container"/>
            <h1 className='group-hover:text-wwlOrange group-hover:-rotate-12  transition-all duration-300 absolute top-2/4 left-2/4 text-base -translate-x-2/4 -translate-y-2/4 font-chivo font-black text-wwlDarkBlue'>{categoryTitle}</h1>
        </div>
    );
};

export default CategoryCard;