import React, {useEffect, useRef, useState} from "react";
import IngredientChip from "./IngredientChip";

function ChipInput({initChips, parentCb}) {
  const [inputValue, setInputValue] = useState("");
  const [chips, setChips] = useState(initChips);
  const inputRef = useRef(null);

  const handleKeyDown = (event) => {
    if (event.key === "Enter" || event.key === ",") {
      event.preventDefault();
      const value = inputValue.trim().replace(",", "");
      if (value) {
        setChips([...chips, value]);
        setInputValue("");
        parentCb([...chips, value]);
      }
      // dispatch(atlasSearchRecipes());
    }

  };

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const removeChip = (index) => {
    const updatedChips = chips.filter((_, i) => i !== index);
    setChips(updatedChips);
    parentCb(updatedChips);
  };

  // Focus input on mount
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <div className=" pb-2 rounded-lg font-chivo">
      <input
        ref={inputRef}
        type="text"
        value={inputValue}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        className={
          chips.length > 0
            ? "focus:border-wwlOrange focus:ring-wwlOrange flex-1 outline-none w-full focus-none rounded-md mb-2"
            : "focus:border-wwlOrange focus:ring-wwlOrange flex-1 outline-none w-full focus-none rounded-md"
        }
        placeholder="Type and press 'Enter'"
      />
      <div className="flex flex-wrap gap-1">
        {chips.map((chip, index) => (
          <IngredientChip
            handleRemoveChip={() => removeChip(index)}
            text={chip}
          />
        ))}
      </div>
    </div>
  );
}

export default ChipInput;
