import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { updateSearchFilters } from "../../../redux/atlas/atlasSlice";

const options = [
  { id: 1, name: "Best Match", value: "score" },
  { id: 2, name: "Published Date", value: "publish_date" },
  { id: 3, name: "Rating", value: "average_rating" },
  { id: 4, name: "Cook Time", value: "cook_time" },
  { id: 5, name: "Prep Time", value: "prep_time" },
];

const sortOptions = [
  { id: 1, name: "Descending", value: "desc" },
  { id: 2, name: "Ascending", value: "asc" },
];

function Dropdown({ options, selectedFilters, setFilters, type }) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(
    type === "Key" ? selectedFilters.key : selectedFilters.direction
  );
  const dropdownRef = useRef(null);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);

    if (type === "Key")
      setFilters((prevState) => ({ ...prevState, key: option }));
    else if (type === "Direction")
      setFilters((prevState) => ({
        ...prevState,
        direction: option,
      }));
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [dropdownRef]);

  return (
    <div className="relative w-full" ref={dropdownRef}>
      <button
        className="border text-wwlGray500 rounded-xl w-full px-3 py-2 focus:outline-none focus:border-wwlOrange bg-wwlWhite"
        onClick={toggleDropdown}
      >
        {selectedOption.name}
        <span className="float-right mt-1">
          <svg
            className="w-4 h-4 text-gray-400"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M19 9l-7 7-7-7"
            ></path>
          </svg>
        </span>
      </button>
      {isOpen && (
        <ul className="absolute z-10 w-full bg-white border border-gray-200 rounded-md mt-1 max-h-60 overflow-auto">
          {options.map((option) => (
            <li
              key={option.id}
              className="py-2 px-4 hover:bg-gray-100 cursor-pointer"
              onClick={() => handleOptionClick(option)}
            >
              {option.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default function SearchSortOptions({ filters, setFilters }) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(updateSearchFilters(filters));
  }, [filters]);

  return (
    <div className="flex flex-col space-y-2 sm:flex-row sm:space-x-2 sm:space-y-0 font-chivo max-w-lg">
      <Dropdown
        label="Select Option"
        options={options}
        selectedFilters={filters}
        setFilters={setFilters}
        type={"Key"}
      />
      <Dropdown
        label="Direction"
        options={sortOptions}
        selectedFilters={filters}
        setFilters={setFilters}
        type={"Direction"}
      />
    </div>
  );
}
