import React from "react";

export default function GetStarted() {
  return (
    <div className="font-chivo w-full py-8 text-center">
      <h1 className="text-xl font-bold ">
        Type a search to get started!
      </h1>
      <p className="text-sm p-1">Use ingredients to help filter your results.</p>
    </div>
  );
}
