import React from "react";
import { XCircleIcon } from "@heroicons/react/solid";

const IngredientChip = ({ handleRemoveChip, text, index }) => {
  return (
    <div
      className="bg-wwlOrange inline-block pl-3 pr-2 py-1 rounded-full shrink-0 whitespace-nowrap"
      contentEditable={false}
    >
      <div className="flex items-center">
        <h2 className="text-sm font-chivo text-wwlWhite">{text}</h2>
        <XCircleIcon
          className="h-5 w-5 text-wwlWhite ml-2 cursor-pointer"
          onClick={handleRemoveChip}
        />
      </div>
    </div>
  );
};

export default IngredientChip;
