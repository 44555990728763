import React, { forwardRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectClickedOutside,
  selectCurrentUser,
} from "../../../redux/user/userSlice";
import {
  BookmarkIcon as BookmarkIconSolid,
  HeartIcon as HeartIconSolid,
  StarIcon,
} from "@heroicons/react/solid";
import NewRecipeCardMealPlanAdd from "./NewRecipeCardMealPlanAdd";
import { BookmarkIcon, HeartIcon } from "@heroicons/react/outline";
import { addBookmarked, addFavorite } from "../../../redux/user/userThunk";
import { useNavigate } from "react-router-dom";
import { setCurrentRecipe } from "../../../redux/recipe/recipesSlice";

const NewRecipeCard = forwardRef((props, ref) => {
  const { recipe, setOpenRecipeDetailModal } = props;
  const [show, setShow] = useState(false);
  const clicked = useSelector(selectClickedOutside);
  const currentUser = useSelector(selectCurrentUser);
  const dispatch = useDispatch();
  const menuInCenter = true;
  const navigate = useNavigate();

  function showRecipeModal() {
    dispatch(setCurrentRecipe(recipe));
    navigate(`/explore-recipes?page=1&recipeId=${recipe._id}`);
    setOpenRecipeDetailModal(true);
    
  }

  return (
    <div
      key={recipe.title}
      className="h-96 w-full bg-white flex flex-col rounded-xl shadow-lg hover:shadow-sm transition-all duration-300 font-chivo"
      ref={ref}
    >
      <div
        onClick={() => showRecipeModal()}
        className="flex-1 overflow-hidden cursor-pointer"
      >
        <img
          src={recipe.primary_image}
          alt={recipe.title}
          className="object-cover w-full h-full rounded-t-xl"
        />
      </div>
      <div className="h-36 space-y-2 bg-white rounded-b-xl flex flex-col justify-between">
        <div
          onClick={() => showRecipeModal()}
          className="flex items-start justify-between cursor-pointer flex-1 p-3"
        >
          <div className="flex flex-col">
            <div className="text-md line-clamp-2">{recipe.title}</div>
          </div>
          <div className="flex items-start space-x-1 pl-2 text-yellow-400">
            {/* <div className="flex flex-col w-24 text-wwlBlue">
              <div className="text-sm">Prep: {recipe.prep_time}<span className="text-xs">mins</span></div>
              <div className="text-sm">Cook: {recipe.cook_time}<span className="text-xs">mins</span></div>
            </div> */}
            <div className="flex items-center">
              <StarIcon className="h-5 w-5" />
              <div className="text-lg text-wwlBlue">
                {recipe.average_rating.toFixed(1)}{" "}
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-between items-center rounded-b-lg px-3 pb-3">
          <NewRecipeCardMealPlanAdd
            show={show}
            setShow={setShow}
            recipe={recipe}
            clickedOutside={clicked}
            menuInCenter={menuInCenter}
            currentUser={currentUser}
          />
          <div className="flex space-x-2">
            <div
              className="cursor-pointer"
              onClick={() => dispatch(addFavorite(recipe._id))}
            >
              {currentUser?.favorite_recipes?.includes(recipe._id) ? (
                <HeartIconSolid className="h-6 w-6 text-wwlOrange hover:text-wwlOrange/50 transition-all" />
              ) : (
                <HeartIcon className="h-6 w-6 hover:text-wwlOrange transition-all" />
              )}
            </div>
            <div
              className="cursor-pointer"
              onClick={() => dispatch(addBookmarked(recipe._id))}
            >
              {currentUser?.bookmarked_recipes?.includes(recipe._id) ? (
                <BookmarkIconSolid className="h-6 w-6 text-wwlOrange hover:text-wwlOrange/50 transition-all" />
              ) : (
                <BookmarkIcon className="h-6 w-6 hover:text-wwlOrange transition-all" />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default NewRecipeCard;
